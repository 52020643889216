<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardBody>
            <center><h1>Test Result</h1></center></CCardBody
          ></CCard
        >
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12" md="4" lg="4">
        <CWidgetSimple header="Attempted question" :text="attemptedText">
          <CProgress animated color="danger" height="0.8rem"
          :value=testData.attemptedQuestions*100/testData.totalQuestions />
        </CWidgetSimple>
      </CCol>
      <CCol sm="12" md="4" lg="4">
        <CWidgetSimple
          header="Correct Answer"
          :text="testData.correctAnswers + '/' + testData.totalQuestions"
        >
          <CProgress animated color="danger" height="0.8rem"
          :value=testData.correctAnswers*100/testData.totalQuestions />
        </CWidgetSimple>
      </CCol>
      <CCol sm="12" md="4" lg="4">
        <CWidgetSimple
          header="Score"
          :text="testData.score + '/' + testData.maxScore"
        >
          <CProgress animated color="danger" height="0.8rem"
          :value=testData.score*100/testData.maxScore />
        </CWidgetSimple>
      </CCol>
    </CRow>
    <CCard>
      <CDataTable :items="moduleData" :fields="fields" hover striped>
        <template #timeTaken="{ item }">
          <td class="py-2">
            {{ Math.floor(item.timeTaken / 60) }} mins
            {{ item.timeTaken % 60 }} sec
          </td>
        </template>
      </CDataTable>
    </CCard>
    <CCard>
      <CCardHeader>
        <span>Total Question Analysis</span>
      </CCardHeader>
      <CChartDoughnut
        style="height: 300px"
        :datasets="QChartData"
        :labels="['Incorrect Answer', 'Correct Answer', 'Not Attempted']"
        :options="{ maintainAspectRatio: false }"
      />
    </CCard>
    <CCard>
      <CCardHeader> Section-wise Question Analsis </CCardHeader>
      <CChartDoughnut
        style="height: 300px"
        :datasets="QmoduleChartData"
        :labels="['Incorrect Answer', 'Correct Answer', 'Not Attempted']"
        :options="{ maintainAspectRatio: false }"
      />
    </CCard>
    <!-- 
    <CCard>
      <CCardHeader>
        <span>Time Analysis</span>
      </CCardHeader>
      <CChartBar
        style="height: 300px"
        :datasets="QChartData"
        :labels="['Incorrect Answer', 'Correct Answer', 'Not Attempted']"
        :options="{ maintainAspectRatio: false }"
      />
      <CChartBar
        style="height: 300px"
        :datasets="QmoduleChartData"
        :labels="['Incorrect Answer', 'Correct Answer', 'Not Attempted']"
        :options="{ maintainAspectRatio: false }"
      />

    <CChartRadar
      style="height: 300px; display: none"
      :datasets="[
        {
          label: 'module A',
          backgroundColor: 'rgba(200,0,0,0.2)',
          data: [65, 75, 70, 80],
        },
        {
          label: 'module B',
          backgroundColor: 'rgba(0,0,200,0.2)',
          data: [54, 65, 60, 70],
        },
      ]"
      :labels="['AttemptedQuestions', 'Score', 'TimeTaken', 'Correct Answer']"
      :options="{ maintainAspectRatio: false }"
    />

    </CCard>
 -->
    <CCard>
      <CCardBody class="text-center">
        <h4>Thank you for taking this exam</h4>
        <p>Results will be sent to assessment agency</p>
        <CRow>
          <CCol>
        <CButton color="primary" @click="logout">Close Test</CButton>
          
        <CButton color="primary" @click="preview">Preview Test</CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

 


<script>
import { ajaxCallMixin } from "@/mixins/HttpCommon";
import { localDb } from "@/mixins/localDb";

export default {
  name: "Result",
  mixins: [ajaxCallMixin, localDb],

  data() {
    return {
      fields: [
        { key: "moduleName", label: "Section Name" },
        "score",
        "attemptedQuestions",
        "correctAnswers",
        "totalQuestions",
        "timeTaken",
      ],
      details: [],
      courseName: "",
      collapseDuration: 0,
      showCourseList: false,
      companyList: {},
      moduleData: {},
      attemptedText: "",
      testData: {},
      radarData: {},
      QChartData: [],
      QmoduleChartData: [],
    };
  },
  created() {
    this.getTestAnalysis();
  },
  methods: {
    getTestAnalysis() {
      var param = {};
      var data = {};
      var url = "test/test/getTestAnalysis";
      data.sendModuleArrayValues = true;
      param.data = data;
      param.token = this.getToken();
      this.ajaxCall(url, param, this.populateAnalysis);
    },
    populateAnalysis(apiResponse) {
      if (apiResponse.error != true) {
        var testData,
          moduleData = [];
        testData = this.testData = apiResponse.data.testData;
        moduleData = this.moduleData = apiResponse.data.moduleData;

        this.QChartData = [
          {
            data: [
              testData.attemptedQuestions - testData.correctAnswers,
              testData.correctAnswers,
              testData.totalQuestions - testData.attemptedQuestions,
            ],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(0, 211, 95)",
              "rgb(255, 205, 86)",
              "rgb(54, 102, 200)",
            ],
          },
        ];

        var moduleDetails = [];
        /* var scoreAnalysis = [];
      var timeAnalysis = [];
      var labels = []; */
        moduleData.forEach(function (mdata) {
          moduleDetails.push({
            label: mdata.moduleName,
            data: [
              mdata.attemptedQuestions - mdata.correctAnswers,
              mdata.correctAnswers,
              mdata.totalQuestions - mdata.attemptedQuestions,
            ],

            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(0, 211, 95)",
              "rgb(255, 205, 86)",
              "rgb(54, 102, 200)",
            ],
          });
        });

        this.QmoduleChartData = moduleDetails;

        /* this.radarData = {
          labels: [
            "AttemptedQuestions",
            "Score",
            "TimeTaken",
            "Correct Answer",
          ],
          datasets: [
            {
              label: "Student A",
              backgroundColor: "rgba(200,0,0,0.2)",
              data: [65, 75, 70, 80],
            },
            {
              label: "Student B",
              backgroundColor: "rgba(0,0,200,0.2)",
              data: [54, 65, 60, 70],
            },
          ],
        } ;*/
      }

      this.attemptedText =
        this.testData.attemptedQuestions + "/" + this.testData.totalQuestions;
    },
    logout() {
      this.remove("examData", "local");
      window.close();
      this.$router.push("/auth/login");
    },
    beforeRouteLeave(to, from, next) {
      if (to.name === "Login") next();
      else next(false);
    },

    preview() {
      this.save("previewAnswer", true, "session");
      this.save("isExamInProgress", true, "session");
      this.$store.commit("setExamStatus", true);
      this.$router.push("/exam/details");
    },
  },
};
</script>